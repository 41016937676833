<template scoped>
  <CRUD-CreateUpdate
    title="User"
    :toPage="toPage"
    :endpoint="endpoint"
    :parameter="parameter"
    apiPath="Backend/User"
    :back="back"
    :fields="fields"
  >
  </CRUD-CreateUpdate>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  components: {
    "CRUD-CreateUpdate": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-CreateUpdate.vue"),
  },
  data: function () {
    return {
      fields: [
        {
          name: "name",
          label: "Name",
          type: "String",
        },
      ],
    };
  },
  methods: {
    back: function () {
      this.toPage(
        "components/User/Portal-User.vue"
      );
    },
  },
};
</script>
